import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export interface PropsTitle {
  variant?: string;
  component?: string;
}

export const Text = styled(Typography)<PropsTitle>`
  font-weight: 600;
  color: ${(props) => props.theme.palette.text.primary};
`;
