import React, { memo, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material/styles';

import main from 'assets/styled/themes';

interface Props {
  children: ReactNode;
}

const Theme: React.FC<Props> = ({ children }) => <ThemeProvider theme={main}>{children}</ThemeProvider>;

export default memo(Theme);
