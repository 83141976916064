import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AttachmentsConclusion from 'components/attachmentsConclusion';

import { Container, Item, Title, Subtitle, Description } from './styled';

type Attachment = {
  ext: string;
  url: string;
  file_name: string;
};

interface Props {
  definition: string | undefined;
  observation: string | undefined;
  attachment?: Attachment | null;
}

const Conclusion: React.FC<Props> = memo(({ definition, observation, attachment }) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Title>{translate('searchComplaint.conclusion.title')}</Title>
      <Container>
        <Item>
          <Subtitle>{translate('searchComplaint.conclusion.definition')}</Subtitle>
          <Description>{definition}</Description>
        </Item>
        <Item>
          <Subtitle>{translate('searchComplaint.conclusion.observation')}</Subtitle>
          <Description>{observation}</Description>
        </Item>
        {attachment && attachment?.url && (
          <Item>
            <Subtitle>{translate('searchComplaint.conclusion.attachment')}</Subtitle>
            <AttachmentsConclusion ext={attachment?.ext} url={attachment?.url} file_name={attachment?.file_name} />
          </Item>
        )}
      </Container>
    </>
  );
});

export default Conclusion;
