import { useCallback, useRef } from 'react';

import exportAsImage from './exportAsImage';

export default function useConfirm(code: string) {
  const ref = useRef(null);

  const onDownload = useCallback(() => {
    exportAsImage(ref.current, code);
  }, [code]);

  return {
    ref,
    onDownload,
  };
}
