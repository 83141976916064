import React, { memo } from 'react';

import {
  Container,
  Content,
  Message,
  Wrapper,
  Image,
  WrapperVideo,
  ConatinerVideo,
  GroupAudios,
  FileImage,
  File,
  IconFile,
  TextDate,
} from './styled';

interface PropsComent {
  iam: boolean;
  date: string;
  message: string;
  ext?: string;
  url?: string;
}

interface PropsAttachment {
  iam: string;
  ext: string;
  url: string;
}

const Attachment: React.FC<PropsAttachment> = ({ ext, url, iam }) => {
  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'svg' || ext === 'gif') {
    return (
      <Wrapper>
        <FileImage href={url} target="_blank">
          <Image src={url} />
        </FileImage>
      </Wrapper>
    );
  }
  if (ext === 'mp4') {
    return (
      <WrapperVideo>
        <ConatinerVideo>
          <video controls>
            <source src={url} />
          </video>
        </ConatinerVideo>
      </WrapperVideo>
    );
  }
  if (ext === 'mp3') {
    return (
      <GroupAudios>
        <audio controls>
          <source src={url} type="audio/mpeg" />
        </audio>
      </GroupAudios>
    );
  }

  return (
    <Wrapper>
      <File href={url} target="_blank">
        <IconFile iam={iam.toString()} />
      </File>
    </Wrapper>
  );
};

const Coment: React.FC<PropsComent> = memo(({ date, message, ext, url, iam = false }) => {
  return (
    <Container iam={iam.toString()}>
      {message && (
        <Content iam={iam.toString()}>
          <Message>{message}</Message>
          <TextDate>{date}</TextDate>
        </Content>
      )}
      {url && ext && (
        <Content iam={iam.toString()}>
          <Attachment ext={ext} url={url} iam={iam.toString()} />
          <TextDate>{date}</TextDate>
        </Content>
      )}
    </Container>
  );
});

export default Coment;
