import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import MainProvider from 'hooks/main';
import { Theme } from 'components/layout';
import Routes from 'routes';

import 'locales';

const App: React.FC = () => {
  return (
    <MainProvider>
      <Theme>
        <CssBaseline />
          <Routes />
      </Theme>
    </MainProvider>
  );
};

export default App;
