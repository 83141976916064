import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ListAttachments from 'components/listAttachments';

import { Container, Item, Title, Description, Date, Status } from './styled';

interface PropsAttachment {
  ext: string;
  url: string;
  file_name: string;
}

interface Props {
  sentBy: string;
  description: string;
  images?: PropsAttachment[];
  videos?: PropsAttachment[];
  audios?: PropsAttachment[];
  documents?: PropsAttachment[];
  date: string;
  status: 'initiated' | 'under-analysis' | 'pending' | 'finished' | 'open';
}

const Message: React.FC<Props> = memo(({ sentBy, description, images, videos, audios, documents, date, status }) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Item status={status}>
        <Status status={status}>{translate(`status.${status}`)}</Status>
        <Date>{date}</Date>
      </Item>
      <Item status={status}>
        <Title status={status}>{sentBy || translate('searchComplaint.anonymousReport')}</Title>
        <Description>{description}</Description>
      </Item>
      {images && images.length > 0 && (
        <Item status={status}>
          <Title status={status}>{translate('searchComplaint.photos')}</Title>
          <ListAttachments images={images} />
        </Item>
      )}
      {videos && videos.length > 0 && (
        <Item status={status}>
          <Title status={status}>{translate('searchComplaint.videos')}</Title>
          <ListAttachments videos={videos} />
        </Item>
      )}
      {audios && audios.length > 0 && (
        <Item status={status}>
          <Title status={status}>{translate('searchComplaint.audios')}</Title>
          <ListAttachments audios={audios} />
        </Item>
      )}
      {documents && documents.length > 0 && (
        <Item status={status}>
          <Title status={status}>{translate('searchComplaint.documents')}</Title>
          <ListAttachments documents={documents} />
        </Item>
      )}
    </Container>
  );
});

export default Message;
