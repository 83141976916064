import React, { ReactNode } from 'react';

import ChangeThemeProvider from 'hooks/theme';
import NotificationProvider from 'hooks/notification';

interface Props {
  children: ReactNode;
}

const MainProvider: React.FC<Props> = ({ children }) => {
  return (
    <NotificationProvider>
      <ChangeThemeProvider>{children}</ChangeThemeProvider>
    </NotificationProvider>
  );
};

export default MainProvider;
