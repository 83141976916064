import { styled } from '@mui/material/styles';

export const Container = styled('ul')`
  width: 100%;
  padding: 20px 10px;
  border: 2px solid rgb(122, 122, 122);
  border-radius: 5px;
  list-style: none;
  margin-top: 10px;
`;

export const Item = styled('li')`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:not(:last-child) {
    border-bottom: 2px solid rgb(122, 122, 122);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
  }
`;

export const Title = styled('h3')`
  font-size: 20px;
  font-weight: 700;
`;

export const Subtitle = styled('span')`
  font-size: 18px;
  font-weight: 700;
  display: block;
`;

export const Description = styled('span')`
  font-size: 16px;
  display: block;
`;
