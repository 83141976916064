import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Page404() {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    document.title = translate('page404.head.title');

    setTimeout(() => {
      setLoading(false);
    }, 50);

    setTimeout(() => {
      setShow(true);
    }, 100);
  }, [translate]);

  return {
    loading,
    show,
    translate,
    goBack,
  };
}
