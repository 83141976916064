import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setPrefix } from 'utils';

export default function useComplaint() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const goToPage = useCallback(
    (link: string) => {
      navigate(setPrefix(translate(link)));
    },
    [navigate, translate],
  );

  useEffect(() => {
    document.title = translate('complaint.head.title');
  }, [translate]);

  return {
    translate,
    goToPage,
  };
}
