import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import imgLoading from 'assets/images/loading.svg';

interface PropsForm {
  disabled?: boolean;
}

interface PropsFormGroup {
  space?: boolean;
}

interface PropsError {
  error?: boolean;
}

export interface PropsContainerSearchCode {
  disabled: boolean;
}

export const ContainerSearchCode = styled('div')<PropsContainerSearchCode>`
  && {
    width: 100%;
    position: relative;

    label {
      &.Mui-disabled {
        opacity: 0.5;
        color: ${(props: any) => props.theme.palette.primary.main};
      }
    }

    .Mui-disabled {
      input {
        color: ${(props: any) => props.theme.palette.primary.main};
        -webkit-text-fill-color: ${(props: any) => props.theme.palette.primary.main};
      }
    }
  }
`;

export const ButtonClear = styled(IconButton)`
  && {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`;

export const IconClear = styled(ClearOutlinedIcon)`
  color: ${(props: any) => props.theme.palette.primary.main};
`;

export const FormCustom = styled('form')<PropsForm>`
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
  opacity: ${(props: any) => (props.disabled ? 0.2 : 1)};

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${(props: any) => (props.disabled ? 10 : -5)};
  }

  &.loading-form {
    transform: scale(0.97);
    filter: blur(1.3px);
    opacity: 0.7;

    &:before {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &:after {
      width: 100%;
      height: 100%;
      content: '';
      background-image: url(${imgLoading});
      background-repeat: no-repeat;
      background-size: 70px;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 15;
    }
  }
`;

export const FormGroupCustom = styled(Box)<PropsFormGroup>`
  width: 100%;
  margin-bottom: ${(props: any) => (props.space === true ? 20 : 0)}px;
`;

export const InputCustom = styled(TextField)`
  width: 100%;
`;

export const FormControlCustom = styled(FormControl)`
  width: 100%;
`;

export const InputLabelCustom = styled(InputLabel)<PropsError>`
  && {
    ${(props) => (props.error === true ? `color: ${props.theme.palette.error.main}` : '')};

    &.Mui-focused {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

export const InputSelectCustom = styled(Select)`
  && {
    width: 100%;

    svg {
      cursor: default;
    }

    &.Mui-error {
      svg {
        color: ${(props) => props.theme.palette.error.main};
      }
    }
  }
`;

export const OptionSelect = styled(MenuItem)``;

export const IconAttach = styled(AttachmentOutlinedIcon)``;
