import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import Img from 'components/image';

interface PropsIam {
  iam?: string;
}

export const Container = styled(Box)<PropsIam>`
  max-width: calc(100% - 40vh);
  min-width: 200px;
  float: ${(props: any) => (props.iam === 'true' ? 'right' : 'left')};
  clear: both;
  margin-left: ${(props: any) => (props.iam === 'true' ? '0' : '20px')};
  margin-right: ${(props: any) => (props.iam === 'true' ? '20px' : '0')};
  position: relative;

  @media (max-width: 768px) {
    max-width: 240px;
  }
`;

export const Content = styled(Box)<PropsIam>`
  min-width: 200px;
  transition: all 0.4s ease-in-out 0s;
  opacity: 1;
  transform: translateY(0px);
  padding: 15px 10px;
  background-color: ${(props: any) =>
    props.iam === 'true' ? props.theme.message.iam.default : props.theme.message.primary.default};
  position: relative;
  border-radius: 6px;
  margin-bottom: 50px;
  display: table;
  cursor: default;

  &:before {
    width: 0;
    height: 0;
    content: '';
    transition: all 0.4s ease-in-out 0s;
    border: 10px solid transparent;
    border-left-width: ${(props: any) => (props.iam === 'true' ? '15px' : '10px')};
    border-right-width: ${(props: any) => (props.iam === 'true' ? '10px' : '15px')};
    border-left-color: ${(props: any) => (props.iam === 'true' ? props.theme.message.iam.default : 'transparent')};
    border-right-color: ${(props: any) => (props.iam === 'true' ? 'transparent' : props.theme.message.primary.default)};
    position: absolute;
    top: 4px;
    left: ${(props: any) => (props.iam === 'true' ? 'auto' : '-20px')};
    right: ${(props: any) => (props.iam === 'true' ? '-20px' : 'auto')};
    z-index: -1;
  }

  &:hover {
    background-color: ${(props: any) =>
      props.iam === 'true' ? props.theme.message.iam.main : props.theme.message.primary.main};

    &:before {
      border-left-color: ${(props: any) => (props.iam === 'true' ? props.theme.message.iam.main : 'transparent')};
      border-right-color: ${(props: any) => (props.iam === 'true' ? 'transparent' : props.theme.message.primary.main)};
    }
  }
`;

export const Message = styled('span')`
  color: #fff;
  font-size: 16px;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
`;

export const TextDate = styled('span')`
  color: ${(props: any) => props.theme.message.primary.contrastText};
  font-size: 14px;
  text-align: center;
  line-height: 1.4;
  word-break: break-all;
  position: absolute;
  right: 10px;
  bottom: -23px;
`;

export const Wrapper = styled(IconButton)`
  && {
    display: flex;
    background: ${(props) => props.theme.palette.background.paper};
    padding: 0;
    margin: 0;
  }
`;

export const Image = styled(Img)`
  max-width: 200px;
  height: auto;
`;

export const FileImage = styled(Link)`
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const File = styled(Link)`
  min-width: 200px;
  max-width: 200px;
  height: 90px;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconFile = styled(FileCopyIcon)<PropsIam>`
  color: ${(props: any) => (props.iam === 'true' ? props.theme.message.iam.light : props.theme.message.primary.light)};
`;

export const WrapperVideo = styled('div')`
  width: 100%;
  min-width: 500px;
  max-width: 500px;
  height: auto;

  @media (max-width: 768px) {
    min-width: 240px;
    max-width: 240px;
  }
`;

export const ConatinerVideo = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

export const GroupAudios = styled('div')`
  display: flex;
  flex-direction: column;

  audio {
    width: 100%;
    min-width: 500px;
    max-width: 500px;

    @media (max-width: 768px) {
      min-width: 240px;
      max-width: 240px;
    }
  }
`;
