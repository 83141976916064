import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Complaint from 'pages/complaint';
import MakeComplaint from 'pages/makeComplaint';
import SearchComplaint from 'pages/searchComplaint';
import Page404 from 'pages/404';

import { setPrefix } from 'utils';

const RoutesPublic: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Router>
      <Routes>
        <Route path={setPrefix('/')} element={<Complaint />} />
        <Route path={setPrefix(translate('route.make'))} element={<MakeComplaint />} />
        <Route path={setPrefix(translate('route.search'))} element={<SearchComplaint />} />
        <Route path={setPrefix(translate('route.code'))} element={<SearchComplaint />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export default RoutesPublic;
