import React from 'react';

import Fade from 'components/fade';

import useComplaint from './useComplaint';

import logo from 'assets/images/logo-grupo-altum.png';
import alarm from 'assets/images/alarm.svg';
import search from 'assets/images/search.svg';

import {
  Header,
  Container,
  Center,
  Logo,
  GroupButton,
  Button,
  Group,
  LabelButton,
  IconComplaint,
  Iconsearch,
} from './styled';

const Complaint: React.FC = () => {
  const { translate, goToPage } = useComplaint();

  return (
    <>
      <Fade show={true}>
        <Center>
          <Header>
            <Logo src={logo} />
          </Header>
          <Container maxWidth="xl">
            <GroupButton>
              <Button color="primary" onClick={() => goToPage('route.make')}>
                <Group>
                  <IconComplaint src={alarm} />
                  <LabelButton>{translate('complaint.makeComplaint')}</LabelButton>
                </Group>
              </Button>
              <Button color="primary" onClick={() => goToPage('route.search')}>
                <Group>
                  <Iconsearch src={search} />
                  <LabelButton>{translate('complaint.followUpComplaint')}</LabelButton>
                </Group>
              </Button>
            </GroupButton>
          </Container>
        </Center>
      </Fade>
    </>
  );
};

export default Complaint;
