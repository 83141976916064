import axios from 'axios';

export { axios };

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers.common['uuid'] = `${process.env.REACT_APP_UUID}`;

api.interceptors.response.use(
  (response) => response.data,
  (error: any) => Promise.reject(error.response.data),
);

export default api;
