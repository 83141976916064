import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const Wrapper = styled('div')``;

export const GroupImages = styled('div')`
  && {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const GroupVideos = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const GroupAudios = styled('div')`
  display: flex;
  flex-direction: column;

  audio {
    width: 100%;
    max-width: 500px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const GroupDocuments = styled('div')``;

export const BaseLink = styled(IconButton)`
  && {
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
  }
`;

export const BaseDocument = styled(IconButton)`
  && {
    display: flex;
    background-color: ${(props) => props.theme.palette.primary.light};
    padding: 10px 20px;
    border-radius: 10px;

    &:not(:last-child) {
      margin: 0 0 10px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    a {
      font-size: 16px;
      color: ${(props) => props.theme.palette.primary.contrastText};
      text-decoration: none;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const Link = styled('a')`
  height: 100%;
  display: flex;
`;

export const Title = styled('a')`
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const WrapperVideo = styled('div')`
  width: 100%;
  max-width: 500px;
  height: 450;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ConatinerVideo = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
