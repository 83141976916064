import { styled } from '@mui/material/styles';

interface PropsStatus {
  status?: 'initiated' | 'under-analysis' | 'pending' | 'finished' | 'open';
}

const getColorStatus = (status: PropsStatus, theme: any) => {
  if (status === 'open' as PropsStatus) {
    return { bg: theme.status.open?.bg, color: theme.status.open?.color };
  }
  if (status === 'finished' as PropsStatus) {
    return { bg: theme.status.finished?.bg, color: theme.status.finished?.color };
  }
  if (status === 'under-analysis' as PropsStatus) {
    return { bg: theme.status.underAnalysis?.bg, color: theme.status.underAnalysis?.color };
  }
  if (status === 'pending' as PropsStatus) {
    return { bg: theme.status.pending?.bg, color: theme.status.pending?.color };
  }
  return { bg: theme.status.initiated?.bg, color: theme.status.initiated?.color };
};

export const Container = styled('ul')`
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

export const Item = styled('li')<PropsStatus>`
  margin: 0;
  list-style: none;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;

  &:not(:last-child) {
    border-left: 2px solid ${(props: any) => getColorStatus(props.status, props.theme).bg};
  }

  &:before {
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    left: -10px;
    background-color: ${(props: any) => getColorStatus(props.status, props.theme).bg};
    border: 1px solid ${(props: any) => getColorStatus(props.status, props.theme).bg};
    border-radius: 50%;
  }
`;

export const Title = styled('h3')<PropsStatus>`
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 20px;
`;

export const Description = styled('span')`
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
`;

export const Date = styled('span')`
  font-size: 14px;
  font-weight: 700;
  display: block;
`;

export const Status = styled('span')<PropsStatus>`
  width: 135px;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props: any) => getColorStatus(props.status, props.theme).color};
  background-color: ${(props: any) => getColorStatus(props.status, props.theme).bg};
  margin-bottom: 10px;
`;
