import React, { memo } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import { FormControlCustom, InputLabelCustom, InputSelectCustom, OptionSelect } from './styled';

interface OptionsProps {
  title: string;
  value: string;
}

interface Props {
  label?: string;
  name?: string;
  options: OptionsProps[];
  value: string;
  error?: boolean;
  helperText?: string;
  onChange: (event: SelectChangeEvent) => void;
}

const InputSelect: React.FC<Props> = ({ options, label, name, value, error, helperText, onChange }) => (
  <FormControlCustom variant="outlined">
    {label && <InputLabelCustom error={error}>{label}</InputLabelCustom>}
    <InputSelectCustom label={label} name={name} onChange={(event: any) => onChange(event)} value={value} error={error}>
      {options?.map((item, index) => (
        <OptionSelect key={index} value={item.value}>
          {item.title}
        </OptionSelect>
      ))}
    </InputSelectCustom>
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControlCustom>
);

export default memo(InputSelect);
