import { ReactNode, ReactElement } from 'react';

import Notification from 'components/notification';

import { useNotificationProvider, NotificationContext } from './useNotification';

interface Props {
  children?: ReactElement | ReactNode;
}

export default function Notificate({ children }: Props) {
  const { notification, openNotification, closeNotification } = useNotificationProvider();

  return (
    <NotificationContext.Provider
      value={{
        notification,
        openNotification,
        closeNotification,
      }}
    >
      {children}
      <Notification
        open={notification.open}
        message={notification.message}
        status={notification.status}
        handleClose={closeNotification}
      />
    </NotificationContext.Provider>
  );
}
