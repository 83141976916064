import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';

export const Modal = styled(Dialog)`
  .MuiPaper-root {
    min-width: 350px;
    min-height: 150px;
    background-color: ${(props) => props.theme.palette.background.paper};
    border-radius: 10px;
  }
`;

export const ButtonClose = styled(IconButton)`
  && {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const IconClose = styled(CloseOutlinedIcon)``;

export const ModalTitle = styled(DialogTitle)`
  && {
    text-align: center;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 700;
  }
`;

export const ModalText = styled(DialogContentText)`
  && {
    text-align: center;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 500;
  }
`;

export const ModalBody = styled(DialogContent)``;

export const ModalActions = styled(DialogActions)`
  && {
    display: flex;
    justify-content: center;

    button {
      padding: 12px 25px;
      margin: 0 10px 15px;
    }
  }
`;

export const ModalStatus = styled(Box)`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: auto;
  }
`;

export const ModalGroupMessage = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ModalTextSuccess = styled('span')`
  && {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
`;

export const Loading = styled(CircularProgress)`
  && {
    width: 60px;
    color: ${(props) => props.theme.palette.success.main};
  }
`;

export const IconSuccess = styled(CheckCircleOutlinedIcon)`
  font-size: 60px;
  color: ${(props) => props.theme.palette.success.main};
  margin-bottom: 10px;
`;

export const IconError = styled(ErrorOutlineOutlinedIcon)`
  font-size: 60px;
  color: ${(props) => props.theme.palette.error.main};
  margin-bottom: 10px;
`;

export const GroupCode = styled(Box)`
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 5px;
  margin: 40px 0;
  position: relative;
`;

export const GroupCodeTitle = styled('span')`
  display: block;
  max-width: 240px;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
  margin: -25px auto auto;
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: 10px;
`;

export const GroupCodeValue = styled('span')`
  display: block;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 38px;
  font-weight: 700;
  text-align: center;
`;

export const TextSmall = styled('span')`
  max-width: 280px;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
  display: block;
  margin: auto;
`;

export const IconDownload = styled(CloudDownloadRoundedIcon)`
  margin-right: 15px;
`;
