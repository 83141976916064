import { useState, useContext, createContext } from 'react';

const themeDefault = 'default';

interface ContextProps {
  themeSelected: string;
  setTheme: any;
}

export const ThemeContext = createContext<ContextProps>({
  themeSelected: themeDefault,
  setTheme: () => {},
});

export function useTheme() {
  const context = useContext(ThemeContext);
  return context;
}

export function useThemeProvider() {
  const [themeSelected, setTheme] = useState<string>(themeDefault);

  return {
    themeSelected,
    setTheme,
  };
}
