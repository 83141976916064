import React, { memo } from 'react';

import NotImage from 'assets/images/image-default.svg';

import { Img } from './styled';

interface PropsTitle {
  className?: string;
  src: string;
  srcSet?: string;
  alt?: any;
  loading?: any;
}

const Image: React.FC<PropsTitle> = memo(({ className, src, srcSet, alt, loading }) => (
  <Img
    className={className}
    src={src}
    srcSet={srcSet}
    alt={alt}
    loading={loading}
    onError={(event: any) => {
      event.target.onerror = null;
      event.target.src = NotImage;
    }}
  />
));

export default Image;
