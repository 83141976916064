import React from 'react';
import Grid from '@mui/material/Grid';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Loading from 'components/loading';
import Fade from 'components/fade';
import Title from 'components/title';
import Form, { InputText, InputSelect, Button, ButtonUpload } from 'components/form';
import Confirm from 'components/confirm';

import useMakeComplaint from './useMakeComplaint';

import logo from 'assets/images/logo-grupo-altum.png';
import alarm from 'assets/images/alarm.svg';

import { Header, Goback, IconGoback, Subheader, Container, Logo, IconComplaint } from './styled';

const MakeComplaint: React.FC = () => {
  const {
    loading,
    fields,
    error,
    confirm,
    translate,
    goBack,
    handleChange,
    handleChangeFile,
    handleRemoveFile,
    handleCloseConfirm,
    handleSubmit,
    handleConfirm,
  } = useMakeComplaint();

  if (loading) {
    return <Loading inner />;
  }

  return (
    <>
      <Fade show={true}>
        <Header>
          <Goback color="secondary" onClick={goBack}>
            <IconGoback />
            {translate('makeComplaint.goback')}
          </Goback>
          <Logo src={logo} />
        </Header>
        <Subheader>
          <IconComplaint src={alarm} />
          <Title variant="h3" component="h1" text={translate('makeComplaint.title')} />
        </Subheader>
        <Container maxWidth="lg">
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <InputText
                  name="name"
                  label={translate('makeComplaint.form.name')}
                  value={fields.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={3}>
                <InputSelect
                  name="state"
                  label={translate('makeComplaint.form.state')}
                  options={fields.optionsStates}
                  value={fields.state}
                  onChange={handleChange}
                  error={Boolean(error.state)}
                  helperText={error.state}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={7} xl={9}>
                <InputText
                  name="city"
                  label={translate('makeComplaint.form.city')}
                  value={fields.city}
                  onChange={handleChange}
                  error={Boolean(error.city)}
                  helperText={error.city}
                />
              </Grid>
              {fields.optionsCompanies?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <InputSelect
                    name="company"
                    label={translate('makeComplaint.form.company')}
                    options={fields.optionsCompanies}
                    value={fields.company}
                    onChange={handleChange}
                    error={Boolean(error.company)}
                    helperText={error.company}
                  />
                </Grid>
              )}
              {fields.optionsCompanyRelationship.length > 0 && (
                <Grid item xs={12} md={fields.optionsCompanies?.length > 0 ? 6 : 12}>
                  <InputSelect
                    name="companyRelationship"
                    label={translate('makeComplaint.form.companyRelationship')}
                    options={fields.optionsCompanyRelationship}
                    value={fields.companyRelationship}
                    onChange={handleChange}
                    error={Boolean(error.companyRelationship)}
                    helperText={error.companyRelationship}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{display: 'none'}}>
                <InputText
                    name="complaint"
                    label={translate('makeComplaint.form.aboutTheComplaint')}
                    value={fields.im_a_robot}
                    onChange={handleChange}
                  />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  multiline
                  minRows={4}
                  maxRows={8}
                  name="complaint"
                  label={translate('makeComplaint.form.complaint')}
                  value={fields.complaint}
                  onChange={handleChange}
                  error={Boolean(error.complaint)}
                  helperText={error.complaint}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonUpload
                  label={translate('makeComplaint.form.attach')}
                  files={fields.attachments}
                  onChange={handleChangeFile}
                  onRemove={handleRemoveFile}
                  error={Boolean(error.attachment)}
                  helperText={error.attachment}
                />
              </Grid>
            </Grid>
            <Button fullWidth type="submit">
              {translate('makeComplaint.form.send')}
            </Button>
          </Form>
        </Container>
      </Fade>
      <Confirm
        open={confirm.open}
        status={confirm.status}
        code={confirm.code}
        anonymous={confirm.anonymous}
        message={confirm.message}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirm}
      />
    </>
  );
};

const Provider = () => (
  <GoogleReCaptchaProvider reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_KEY_SITE)}>
    <MakeComplaint />
  </GoogleReCaptchaProvider>
)

export default Provider;
