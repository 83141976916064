import React, { memo } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Image from 'components/image';

import { Wrapper, GroupImages, GroupDocuments, BaseLink, BaseDocument, Link } from './styled';

type Attachment = {
  ext: string;
  url: string;
  file_name: string;
};

const AttachmentsConclusion: React.FC<Attachment> = memo(({ ext, url, file_name }) => {
  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'svg' || ext === 'gif') {
    return (
      <Wrapper>
        <GroupImages>
          <ImageList>
            <ImageListItem>
              <BaseLink>
                <Link href={url} target="_blank">
                  <Image
                    src={`${url}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={file_name}
                    loading="lazy"
                  />
                </Link>
              </BaseLink>
            </ImageListItem>
          </ImageList>
        </GroupImages>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <GroupDocuments>
        <BaseDocument>
          <Link href={url} target="_blank">
            {file_name}
          </Link>
        </BaseDocument>
      </GroupDocuments>
    </Wrapper>
  );
});

export default AttachmentsConclusion;
