import React, { memo } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface PropsNotification {
  open: boolean;
  autoHideDuration?: number;
  message: string;
  status: 'error' | 'warning' | 'info' | 'success';
  handleClose?: () => void;
}

const Notification: React.FC<PropsNotification> = memo(({ open, autoHideDuration, message, status, handleClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert onClose={handleClose} severity={status}>
      {message}
    </Alert>
  </Snackbar>
));

export default Notification;
