import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';

import useConfirm from './useConfirm';

import {
  Modal,
  ModalStatus,
  ButtonClose,
  IconClose,
  ModalTitle,
  ModalBody,
  ModalText,
  ModalActions,
  ModalGroupMessage,
  ModalTextSuccess,
  Loading,
  IconError,
  GroupCode,
  GroupCodeTitle,
  GroupCodeValue,
  TextSmall,
  IconDownload,
} from './styled';

interface PropsConfirm {
  message?: string;
  open: boolean;
  status: 'loading' | 'success' | 'error' | '';
  code: string;
  anonymous?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const Confirm: React.FC<PropsConfirm> = memo(({ message, open, status, code, anonymous, onClose, onConfirm }) => {
  const { ref, onDownload } = useConfirm(code);
  const { t: translate } = useTranslation();

  const ModalContent = () => {
    switch (status) {
      case 'loading':
        return (
          <ModalStatus>
            <Loading color="primary" />
          </ModalStatus>
        );
      case 'success':
        return (
          <>
            <ModalBody ref={ref}>
              <ModalTitle>{translate('modal.complaint.title')}</ModalTitle>
              <ModalText>{translate('modal.complaint.subtitle')}</ModalText>
              <GroupCode>
                <GroupCodeTitle>{translate('modal.complaint.legend')}</GroupCodeTitle>
                <GroupCodeValue>{code}</GroupCodeValue>
              </GroupCode>
              <TextSmall>
                {anonymous ? translate('modal.complaint.complaintIsAnonymous') : translate('modal.complaint.complaint')}
              </TextSmall>
            </ModalBody>
            <ModalActions>
              <Button onClick={onDownload} color="primary">
                <IconDownload />
                {translate('modal.button.download')}
              </Button>
            </ModalActions>
          </>
        );
      case 'error':
        return (
          <>
            <ModalStatus>
              <ModalGroupMessage>
                <IconError />
                <ModalTextSuccess>{message}</ModalTextSuccess>
              </ModalGroupMessage>
            </ModalStatus>
          </>
        );

      default:
        return (
          <>
            <ModalTitle>{translate('modal.confirm.title')}</ModalTitle>
            <ModalBody>
              <ModalText>{translate('modal.confirm.subtitle')}</ModalText>
            </ModalBody>
            <ModalActions>
              <Button onClick={onClose} color="secondary">
                {translate('modal.button.cancel')}
              </Button>
              <Button color="primary" onClick={onConfirm}>
                {translate('modal.button.confirm')}
              </Button>
            </ModalActions>
          </>
        );
    }
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
    >
      <ButtonClose onClick={onClose}>
        <IconClose />
      </ButtonClose>

      <ModalContent />
    </Modal>
  );
});

export default Confirm;
