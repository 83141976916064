import { createTheme, darken } from '@mui/material/styles';

const colors = {
  paper: '#F9FAF8',
  primary: '#214b8a',
  secondary: '#878686',
  text: ['#333', '#214b8a'],
  highlight: ['#214b8a', '#009143'],
};

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      initiated?: {
        bg?: string;
        color?: string;
      };
      pending?: {
        bg?: string;
        color?: string;
      };
      finished?: {
        bg?: string;
        color?: string;
      };
      open?: {
        bg?: string;
        color?: string;
      };
      underAnalysis?: {
        bg?: string;
        color?: string;
      };
    };
    highlight: {
      primary: string;
      secondary: string;
    };
    primary: {
      default: string;
      main: string;
    };
    iam: {
      default: string;
      main: string;
    };
  }
  interface ThemeOptions {
    status?: {
      initiated?: {
        bg?: string;
        color?: string;
      };
      pending?: {
        bg?: string;
        color?: string;
      };
      finished?: {
        bg?: string;
        color?: string;
      };
      open?: {
        bg?: string;
        color?: string;
      };
      underAnalysis?: {
        bg?: string;
        color?: string;
      };
    };
    highlight: {
      primary: string;
      secondary: string;
    };
    message?: {
      primary: {
        default: string;
        main: string;
        light: string;
        contrastText: string;
      };
      iam: {
        default: string;
        main: string;
        light: string;
        contrastText: string;
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        'html, body, #root': {
          width: '100%',
          height: '100%',
        },
        body: {
          backgroundColor: colors.paper,
        },
        '::-webkit-scrollbar': {
          width: '10px',
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '3px',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: colors.primary,
          borderRadius: '3px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#0e1012',
        },
        code: {
          fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
        },
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
      paper: colors.paper,
    },
    text: {
      primary: colors.text[0],
      secondary: colors.secondary,
      disabled: '#ccc',
    },
    primary: {
      main: colors.primary,
      light: darken(colors.primary, 0.7),
      dark: darken(colors.primary, 0.2),
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      light: darken(colors.secondary, 0.7),
      dark: darken(colors.secondary, 0.2),
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    success: {
      main: '#65ab6f',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 10
  },
  highlight: {
    primary: colors.highlight[0],
    secondary: colors.highlight[1],
  },
  status: {
    initiated: {
      bg: '#222222',
      color: '#fff',
    },
    pending: {
      bg: '#850202',
      color: '#fff',
    },
    finished: {
      bg: '#036c1a',
      color: '#fff',
    },
    open: {
      bg: '#0264bd',
      color: '#fff',
    },
    underAnalysis: {
      bg: '#e6b800',
      color: '#000',
    },
  },
  message: {
    primary: {
      default: '#767676',
      main: darken('#767676', 0.7),
      light: darken('#767676', 0.2),
      contrastText: '#696868',
    },
    iam: {
      default: '#6e8b84',
      main: darken('#6e8b84', 0.7),
      light: darken('#6e8b84', 0.2),
      contrastText: '#696868',
    },
  },
});

export default theme;
