/**
 * Função que seta um prefixo em todas as páginas
 * @method setPrefix
 * @param {string} link recebe a url uma url
 */
export function setPrefix(link: string) {
  if (process.env.REACT_APP_BASE_URL !== '/') {
    return `/${process.env.REACT_APP_BASE_URL?.toString()}${link}`;
  }

  return link;
}

/**
 * Função que seta um prefixo em todas as páginas
 * @method hexorToRgba
 * @param {string} hex recebe a cor em hexadecimal
 * @param {number} alpha recebe um número para a porcentagem da transparencia
 */
export function colorToRgba(hex: string, alpha: number) {
  const [r, g, b]: any = hex.match(/\w\w/g)?.map((item) => parseInt(item, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

/**
 * Função que linca para uma página
 * @method goToPage
 * @param {string} link recebe a url da página
 */
export function goToPage(link: string) {
  return link;
}

/**
 * Função que válida um e-mail
 * @method validateEmail
 * @param { string } email recebe o E-mail
 */
export function validateEmail(email: string) {
  const regexEmail = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (regexEmail.test(email)) {
    return true;
  } else {
    return false;
  }
}

/**
 * Função que linca para uma página
 * @method debounce
 * @param {Function} callback recebe uma função
 * @param {number} delay recebe um tempo em segundos
 */
export function debounce(callback: (...params: any[]) => any, delay: number) {
  let inDebounce: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => callback.apply(this, args), delay);
  };
}

/**
 * Função que retorna um texto curto
 * @method shortText
 * @param {string} text recebe uma string
 * @param {number} limit recebe o total de caracteres para retornar
 */
export function shortText(text: string, limit: number) {
  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }

  return text;
}

/**
 * Função que converte um arquvio base64
 * @method generateBase64
 * @param {any} file recebe uma string
 */
export function generateBase64(file: any) {
  return new Promise((resolve) => {
    let baseURL = null;
    let reader = new FileReader() as any;

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result.split(';base64,')[1];
      resolve(baseURL);
    };
  });
}

/**
 * Função que retorna o primeiro e o último nome
 * @method shortName
 * @param {string} fullname recebe uma string
 */
export function shortName(fullname: string) {
  const [firstName, lastName] = fullname.split(' ').filter(Boolean);
  if (lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName;
}

/**
 * Função que válida tipo de arquivos como imagems, documentos, mp3 e mp4
 * @method validateUploadFiles
 * @param {string} file recebe uma string
 */
export function validateUploadFiles(file: string) {
  const validExtensions =
    /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.mp3|\.mp4|\.xlsx|\.xls|\.doc|\.docx|\.ppt|\.pptx|\.txt|\.pdf)$/i;
  return validExtensions.exec(file);
}
