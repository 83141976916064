import { useState, useContext, createContext, useCallback } from 'react';

type TypeNotification = {
  open: boolean;
  status: 'error' | 'warning' | 'info' | 'success';
  message: string;
};

interface ContextProps {
  notification: TypeNotification;
  openNotification: (message: string, status: 'error' | 'warning' | 'info' | 'success') => void;
  closeNotification: () => void;
}

export const NotificationContext = createContext<ContextProps>({
  notification: { open: false, message: '', status: 'error' },
  openNotification: () => {},
  closeNotification: () => {},
});

export function useNotification() {
  const context = useContext(NotificationContext);
  return context;
}

export function useNotificationProvider() {
  const [notification, setNotification] = useState<TypeNotification>({ open: false, message: '', status: 'error' });

  const openNotification = useCallback((message: string, status: 'error' | 'warning' | 'info' | 'success') => {
    setNotification({ open: true, message, status });
  }, []);

  const closeNotification = useCallback(() => {
    setNotification({ ...notification, open: false });
  }, [notification]);

  return {
    notification,
    openNotification,
    closeNotification,
  };
}
