import { styled } from '@mui/material/styles';
import CustomContainer from '@mui/material/Container';
import CustomButton from '@mui/material/Button';

import Img from 'components/image';

export const Container = styled(CustomContainer)``;

export const Header = styled('header')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
`;

export const Center = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(Img)`
  max-width: 240px;
  height: auto;
  margin: auto;
`;

export const GroupButton = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Button = styled(CustomButton)`
  && {
    max-width: 480px;
    width: 100%;
    height: 200px;
    margin: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.highlight.primary};
    background-image: ${(props) =>
      `linear-gradient(135deg, ${props.theme.highlight.primary} 37%, ${props.theme.highlight.secondary} 75%)`};
    color: ${(props) => props.theme.palette.primary.contrastText};

    &:hover {
      background-color: ${(props) => props.theme.highlight.secondary};
      background-image: ${(props) =>
        `linear-gradient(135deg, ${props.theme.highlight.secondary} 37%, ${props.theme.highlight.primary} 75%)`};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;

export const IconComplaint = styled(Img)`
  max-width: 120px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 50px;
  }
`;

export const Iconsearch = styled(Img)`
  max-width: 120px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 50px;
  }
`;

export const Group = styled('span')`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LabelButton = styled('span')`
  && {
    font-size: 27px;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 1.3;
    }
  }
`;
