import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from "i18next-browser-languagedetector";

import es from './es';
import en from './en-US';
import ptBR from './pt-BR';

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      translation: {
        route: ptBR.route,
        modal: ptBR.modal,
        format: ptBR.format,
        status: ptBR.status,
        validation: ptBR.validation,
        page404: ptBR.page404,
        complaint: ptBR.complaint,
        makeComplaint: ptBR.makeComplaint,
        searchComplaint: ptBR.searchComplaint,
      },
    },
    en: {
      translation: {
        route: en.route,
        modal: en.modal,
        format: en.format,
        status: en.status,
        validation: en.validation,
        page404: en.page404,
        complaint: en.complaint,
        makeComplaint: en.makeComplaint,
        searchComplaint: en.searchComplaint,
      },
    },
    es: {
      translation: {
        route: es.route,
        modal: es.modal,
        format: es.format,
        status: es.status,
        validation: es.validation,
        page404: es.page404,
        complaint: es.complaint,
        makeComplaint: es.makeComplaint,
        searchComplaint: es.searchComplaint,
      },
    },
  },
  lng: window.localStorage.i18nextLng || 'pt-BR',
  fallbackLng: window.localStorage.i18nextLng || 'pt-BR',
  interpolation: {
    escapeValue: false,
  },
});
