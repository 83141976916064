import React, { memo } from 'react';

import { Text } from './styled';

interface PropsTitle {
  text: string;
  variant?: any;
  component?: string;
}

const Title: React.FC<PropsTitle> = memo(({ text, variant, component }) => (
  <Text variant={variant} component={component}>
    {text}
  </Text>
));

export default Title;
