import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const ButtonCustom = styled(Button)`
  && {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    height: 50px;
    border-radius: ${props => props.theme.shape.borderRadius};
    padding: 12px 20px;
    font-weight: 700;
  }
`;
