import React, { memo } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Image from 'components/image';

import {
  Wrapper,
  GroupImages,
  GroupVideos,
  GroupAudios,
  GroupDocuments,
  BaseLink,
  BaseDocument,
  Link,
  WrapperVideo,
  ConatinerVideo,
} from './styled';

type Attachment = {
  ext: string;
  url: string;
  file_name: string;
};

interface Props {
  images?: Attachment[];
  videos?: Attachment[];
  audios?: Attachment[];
  documents?: Attachment[];
}

const ListAttachments: React.FC<Props> = memo(({ images, videos, audios, documents }) => {
  return (
    <Wrapper>
      {images && images.length > 0 && (
        <GroupImages>
          <ImageList
            sx={{ maxWidth: 500, maxHeight: 450 }}
            cols={images.length > 3 ? 3 : 1}
            rowHeight={images.length > 3 ? 164 : 'auto'}
          >
            {images.map((item) => (
              <ImageListItem key={item.url}>
                <BaseLink>
                  <Link href={item.url} target="_blank">
                    <Image
                      src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.file_name}
                      loading="lazy"
                    />
                  </Link>
                </BaseLink>
              </ImageListItem>
            ))}
          </ImageList>
        </GroupImages>
      )}
      {videos && videos.length > 0 && (
        <GroupVideos>
          {videos.map((item) => (
            <WrapperVideo key={item.url}>
              <ConatinerVideo>
                <video controls>
                  <source src={item.url} />
                </video>
              </ConatinerVideo>
            </WrapperVideo>
          ))}
        </GroupVideos>
      )}
      {audios && audios.length > 0 && (
        <GroupAudios>
          {audios.map((item) => (
            <audio controls key={item.url}>
              <source src={item.url} type="audio/mpeg" />
            </audio>
          ))}
        </GroupAudios>
      )}
      {documents && documents.length > 0 && (
        <GroupDocuments>
          {documents.map((item) => (
            <BaseDocument key={item.url}>
              <Link href={item.url} target="_blank">
                {item.file_name}
              </Link>
            </BaseDocument>
          ))}
        </GroupDocuments>
      )}
    </Wrapper>
  );
});

export default ListAttachments;
