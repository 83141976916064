import route from './translations/route.json';
import page404 from './translations/page-404.json';
import modal from './translations/modal.json';
import format from './translations/format.json';
import status from './translations/status.json';
import validation from './translations/validation.json';
import complaint from './translations/complaint.json';
import makeComplaint from './translations/makeComplaint.json';
import searchComplaint from './translations/searchComplaint.json';

const tranlate = {
  route,
  page404,
  status,
  modal,
  format,
  validation,
  complaint,
  makeComplaint,
  searchComplaint,
};

export default tranlate;
