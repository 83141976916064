import { styled, keyframes } from '@mui/material/styles';

export interface Props {
  show: boolean;
}

export const Container = styled('div')<Props>`
  transition: all 0.3s ease-in-out;
  animation-name: ${(props: any) => (props.show === true ? 'none' : FadeAnimation)};
  animation-duration: ${(props: any) => (props.show === true ? '0s' : '0.6s')};
`;

const FadeAnimation = keyframes`
 0% { opacity: 0; visibility: hidden; }
 100% { opacity: 1; visibility: visible; }
`;
