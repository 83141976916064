import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Content, Description, Progress } from './styled';

interface Props {
  inner?: boolean;
}

const Loading: React.FC<Props> = memo(({ inner = false }) => {
  const { t: translate } = useTranslation();
  return (
    <Container inner={inner.toString()}>
      <Content>
        <Description>{translate('modal.loading')}</Description>
        <Progress />
      </Content>
    </Container>
  );
});

export default Loading;
