import { styled } from '@mui/material/styles';
import CustomContainer from '@mui/material/Container';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Img from 'components/image';

import CustomButton from 'components/button';

export const Header = styled('header')`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    @media (max-width: 768px) {
      button {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 35px;
        text-indent: -3333px;
        padding: 0;
        overflow: hidden;
        position: relative;
        left: 20px;

        svg {
          margin: auto;
        }
      }
    }
  }
`;

export const Subheader = styled('div')`
  width: 100%;
  min-height: 120px;
  background-color: ${(props) => props.theme.highlight.primary};
  background-image: ${(props) =>
    `linear-gradient(135deg, ${props.theme.highlight.primary} 37%, ${props.theme.highlight.secondary} 75%)`};
  color: ${(props) => props.theme.palette.primary.contrastText};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.common.white};

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const Center = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(Img)`
  max-width: 240px;
  height: auto;
  margin: auto;

  @media (max-width: 768px) {
    max-width: 180px;
  }
`;

export const Container = styled(CustomContainer)`
  && {
    margin-top: 60px;

    @media (max-width: 768px) {
      padding-bottom: 60px;

      form {
        .MuiBox-root .MuiButton-root,
        .MuiButton-root {
          width: 100%;
        }
      }
    }
  }
`;

export const IconComplaint = styled(Img)`
  max-width: 120px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 60px;
  }
`;

export const Goback = styled(CustomButton)`
  && {
    position: absolute;
    left: 30px;
  }
`;

export const IconGoback = styled(ArrowBackOutlinedIcon)`
  font-size: 20px;
  margin-right: 10px;
`;
