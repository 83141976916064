import { ReactNode, ReactElement } from 'react';
import { useThemeProvider, ThemeContext } from './useTheme';

interface Props {
  children?: ReactElement | ReactNode;
}

export default function Theme({ children }: Props) {
  const { themeSelected, setTheme } = useThemeProvider();

  return (
    <ThemeContext.Provider
      value={{
        themeSelected,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
