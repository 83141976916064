import React, { memo, ReactNode } from 'react';

import { Container } from './styled';

interface Props {
  show: any;
  children: ReactNode;
}

const Title: React.FC<Props> = memo(({ show, children }) => <Container show={show.toString()}>{children}</Container>);

export default Title;
