import { styled } from '@mui/material/styles';

import Img from 'components/image';

interface PropsTransition {
  show: boolean;
}

export const Main = styled('main')`
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;

  & > div {
    position: relative;
  }
`;

export const Transition = styled('div')<PropsTransition>`
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: translateX(${(props) => (props.show ? '0' : '30px')});
`;

export const GroupInfo = styled('div')`
  margin: auto;
  width: 100%;
  max-width: 450px;
  display: table;
  margin: 200px auto auto;
`;

export const Image = styled(Img)`
  margin: auto;
  width: 100%;
  max-width: 500px;
  height: auto;
`;

export const Title = styled('span')`
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.main};
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
`;

export const Description = styled('span')`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #fff;
  margin: 25px 0;
  display: block;
`;

export const GroupBtn = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;

  button {
    margin: auto;
    padding: 12px 30px;
  }
`;
