import React from 'react';
import Grid from '@mui/material/Grid';
import { Main, Transition, GroupInfo, Image, Title, GroupBtn } from './styled';

import Loading from 'components/loading';
import Button from 'components/button';

import usePage404 from './usePage404';

import PageNotfound from 'assets/images/pageNotfound.svg';

const Page404 = () => {
  const { loading, show, translate, goBack } = usePage404();

  if (loading) {
    return <Loading />;
  }

  return (
    <Main>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={10}>
          <Transition show={show}>
            <GroupInfo>
              <Image src={PageNotfound} />
              <Grid container justifyContent="center">
                <Grid item xs={10} md={7}>
                  <Title as="h1">{translate('page404.title')}</Title>
                  <GroupBtn>
                    <Button color="primary" onClick={goBack}>
                      {translate('page404.goback')}
                    </Button>
                  </GroupBtn>
                </Grid>
              </Grid>
            </GroupInfo>
          </Transition>
        </Grid>
      </Grid>
    </Main>
  );
};

export default Page404;
