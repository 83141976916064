import { styled } from '@mui/material/styles';

interface MainProps {
  myTheme: string;
}

export const Main = styled('main')<MainProps>`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
