import React, { memo, ReactNode } from 'react';
import { FormCustom } from './styled';

interface Props {
  children: ReactNode;
  onSubmit?: any;
  loading?: boolean;
  disabled?: boolean;
  encType?: string | undefined;
}

const Form: React.FC<Props> = memo(({ children, onSubmit, loading, disabled, encType }) => (
  <FormCustom
    className={loading ? 'loading-form' : ''}
    onSubmit={onSubmit}
    autoComplete="off"
    method="post"
    disabled={disabled || false}
    encType={encType}
  >
    {children}
  </FormCustom>
));

export default Form;
