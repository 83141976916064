import React, { memo } from 'react';

import { InputText } from 'components/form';

import { ContainerSearchCode, ButtonClear, IconClear } from './styled';

interface Props {
  name: string;
  label: string;
  value: string;
  error: boolean;
  helperText: string | undefined;
  disabled: boolean;
  onChange: (event: Event) => void;
  onClear: () => void;
}

const InputSearchCode: React.FC<Props> = memo(
  ({ name, label, value, error, helperText, disabled, onChange, onClear }) => (
    <ContainerSearchCode disabled={disabled}>
      <InputText
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
      {disabled && (
        <ButtonClear onClick={onClear}>
          <IconClear />
        </ButtonClear>
      )}
    </ContainerSearchCode>
  ),
);

export default InputSearchCode;
