import React from 'react';
import Grid from '@mui/material/Grid';

import Loading from 'components/loading';
import Fade from 'components/fade';
import Title from 'components/title';
import Form, { InputSearchCode, InputText, Button, ButtonUpload } from 'components/form';
import Message from 'components/message';
import Comment from 'components/comment';
import Conclusion from 'components/conclusion';

import useSearchComplaint from './useSearchComplaint';

import logo from 'assets/images/logo-grupo-altum.png';
import search from 'assets/images/search.svg';

import {
  Header,
  Goback,
  IconGoback,
  Subheader,
  Container,
  Logo,
  IconComplaint,
  Complaint,
  GroupResponses,
} from './styled';

const SearchComplaint: React.FC = () => {
  const {
    loading,
    loadingForm,
    loadingFormComment,
    fields,
    error,
    data,
    translate,
    goBack,
    handleChange,
    handleClearCode,
    handleChangeFile,
    handleRemoveFile,
    handleSubmitSearch,
    handleSubmitComment,
  } = useSearchComplaint();

  if (loading) {
    return <Loading inner />;
  }

  return (
    <>
      <Fade show={true}>
        <Header>
          <Goback color="secondary" onClick={goBack}>
            <IconGoback />
            {translate('makeComplaint.goback')}
          </Goback>
          <Logo src={logo} />
        </Header>
        <Subheader>
          <IconComplaint src={search} />
          <Title variant="h3" component="h1" text={translate('searchComplaint.title')} />
        </Subheader>
        <Container maxWidth="lg">
          <Form loading={loadingForm} onSubmit={handleSubmitSearch}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputSearchCode
                  name="code"
                  label={translate('searchComplaint.form.code')}
                  value={fields.code}
                  onChange={handleChange}
                  onClear={handleClearCode}
                  error={Boolean(error.code)}
                  helperText={error.code}
                  disabled={data.showComplaint}
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type="submit" disabled={data.showComplaint}>
                  {translate('searchComplaint.form.search')}
                </Button>
              </Grid>
            </Grid>
          </Form>
          {data.showComplaint && (
            <Complaint>
              <Message
                sentBy={data.sentBy}
                description={data.description}
                images={data?.attachments?.images}
                videos={data?.attachments?.videos}
                audios={data?.attachments?.audios}
                documents={data?.attachments?.documents}
                date={data.date}
                status={data.status}
              />
              {data.comments && data.comments?.length > 0 && (
                <GroupResponses>
                  <Title text={translate('searchComplaint.response')} variant="h5" component="h3" />
                  {data.comments.map((item, index) => (
                    <Comment
                      key={index}
                      iam={item.iam}
                      date={item.date}
                      message={item.message}
                      ext={item.ext}
                      url={item.url}
                    />
                  ))}
                </GroupResponses>
              )}
              {data.status !== 'finished' && (
                <Form loading={loadingFormComment} onSubmit={handleSubmitComment}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Title text={translate('searchComplaint.comment')} variant="h5" component="h3" />
                      <InputText
                        multiline
                        minRows={4}
                        maxRows={8}
                        name="comment"
                        label={translate('searchComplaint.form.comment')}
                        value={fields.comment}
                        onChange={handleChange}
                        error={Boolean(error.comment)}
                        helperText={error.comment}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonUpload
                        label={translate('searchComplaint.form.attach')}
                        files={fields.attachments}
                        onChange={handleChangeFile}
                        onRemove={handleRemoveFile}
                        error={Boolean(error.attachment)}
                        helperText={error.attachment}
                      />
                    </Grid>
                  </Grid>
                  <Button fullWidth type="submit">
                    {translate('searchComplaint.form.send')}
                  </Button>
                </Form>
              )}
              {data.status === 'finished' && (
                <Conclusion
                  definition={data.conclusion?.definition}
                  observation={data.conclusion?.observation}
                  attachment={data.conclusion?.attachment}
                />
              )}
            </Complaint>
          )}
        </Container>
      </Fade>
    </>
  );
};

export default SearchComplaint;
