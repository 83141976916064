import React, { memo, ReactNode } from 'react';
import { ButtonCustom } from './styled';

interface Props {
  fullWidth?: boolean;
  type?: 'submit' | 'button';
  variant?: 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  className?: string;
  transparent?: boolean;
  onClick?: any;
  disabled?: boolean;
  children: ReactNode;
}

const Button: React.FC<Props> = memo(
  ({ fullWidth, type, variant, size, color, className, onClick, disabled, children }) => (
    <ButtonCustom
      fullWidth={fullWidth || false}
      type={type || 'button'}
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonCustom>
  ),
);

export default Button;
