import React, { memo } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

import { Container, Group, Button, ButtonRemove, IconAttach, IconRemove, File, Label, GroupError } from './styled';

interface Props {
  label: string;
  accept?: string;
  files?: File[];
  error?: boolean;
  helperText?: string;
  onChange(event: any): void;
  onRemove(item: any): void;
}

const DEFAULT_TYPES = '.png,.jpg,.jpeg,.svg,.gif.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3,.mp4';

const ButtonUpload: React.FC<Props> = memo(({ label, accept, files, error, helperText, onChange, onRemove }) => (
  <Container>
    {files &&
      files.map((item, index) => (
        <File key={index}>
          <Label>{item.name}</Label>
          <ButtonRemove color="primary" onClick={() => onRemove(item)}>
            <IconRemove />
          </ButtonRemove>
        </File>
      ))}
    <Group>
      <Button color="secondary">
        <IconAttach />
        {label}
        <input accept={accept || DEFAULT_TYPES} type="file" onChange={onChange} alt="" title="" />
      </Button>
    </Group>
    {error && (
      <GroupError>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </GroupError>
    )}
  </Container>
));

export default ButtonUpload;
